import TableContextProvider, { useTableControls } from '../../../../../../../common/components/table/table-context';
import { Col, Row, Space } from 'antd';
import TableBase from '../../../../../../../common/components/table/table-base';
import React from 'react';
import './vehicles.scss';
import { observer } from 'mobx-react-lite';
import addressesStore from 'features/eiq-manage/stores/addresses.store';
import { ColumnVisibility } from 'common/components/table/filters/column-visibility/column-visibility';
import { vehicleInitialColumns } from './vehicle-list.config';
import { getVehicleRowClassName } from 'common/helpers/vehicles.helper';

export const Vehicles = observer(() => {
  const tableControls = useTableControls({
    clientSide: true,
    data: addressesStore.selectedAddressVehicles,
    initialColumns: vehicleInitialColumns,
  });

  return (
    <div>
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto" className="vehiclesControls">
              <ColumnVisibility />
            </Col>
            <Col>
              <Space size={8}></Space>
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} rowClassName={getVehicleRowClassName} />
        </div>
      </TableContextProvider>
    </div>
  );
});
