import { notification } from 'common/utils/notification';
import plateRecognitionContext, { ILincesePlateRecognitionData } from '../stores/license-plate-recognition-context';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { UserTypeEnum } from 'common/enums/user-type.enum';
import featureStore from 'common/stores/features/feature.store';
import { v4 as uuidv4 } from 'uuid';
import { formatCustomDateTimeForLPR } from '../../../common/helpers/date.helper';
import configurationStore from 'common/stores/configuration/configuration.store';

interface IAdmissionData {
  gate: string;
  camera: string;
  licensePlate: string;
  licenseState: string;
  image?: string;
}

interface IGuestUnrecognizedNotificationMessage {
  reasonCode: string;
  data: IAdmissionData;
}

interface IGuestRecognizedNotificationMessage extends IAdmissionData {
  guestFullName?: string;
  residentFullName?: string;
  address?: string;
}

const isValidState = () => {
  if (!userAuthStore.isLogin || userAuthStore.userType !== UserTypeEnum.SecurityOfficer) {
    return false;
  }

  if (!featureStore.isRealTimeNotificationsEnabled) {
    return false;
  }

  return true;
};

export const admissionNotificationHandlers = (addNotification: Function) => ({
  Alert: (message: string) => {
    if (!isValidState()) {
      return;
    }

    notification.warning({ message, duration: 0 });
  },
  GuestRecognized: (message: IGuestRecognizedNotificationMessage) => {
    const { licensePlate, licenseState, camera, image, gate } = message;

    let context: ILincesePlateRecognitionData = {
      camera: camera,
      licensePlate: licensePlate,
      licenseState: licenseState,
      image: image,
      gate: gate,
    };

    plateRecognitionContext.set(context);
  },
  GuestUnrecognized: (message: IGuestUnrecognizedNotificationMessage) => {
    const { reasonCode, data } = message;
    const { licensePlate, licenseState, camera, image, gate } = data;
    const time = new Date().getTime();
    const formattedTime = formatCustomDateTimeForLPR(time);

    const errorMessages: Record<string, string> = {
      UNKNOWN_ERROR: 'License plate number could not be recognized.',
      MULTIPLE_VEHICLES_FOUND: `Multiple vehicles found with license plate ${licensePlate} (${licenseState}).`,
      UNSUPPORTED_REGION: `Unsupported region: ${licenseState}.`,
      UNLISTED_GUEST: `Vehicle ${licensePlate} is not in the system as a resident or a guest (${camera}).`,
    };

    let context: ILincesePlateRecognitionData = {
      camera: camera,
      licensePlate: licensePlate,
      licenseState: licenseState,
      image: image,
      gate: gate,
    };

    plateRecognitionContext.set(context);

    const notificationMessage = errorMessages[reasonCode] || 'Unknown error';

    if (!configurationStore.configuration?.plateScan?.displayFailedNotification) {
      return;
    }

    addNotification({
      id: uuidv4(),
      message: notificationMessage,
      type: 'error',
      time: formattedTime,
      licensePlate: data.licensePlate,
      ...(reasonCode === 'UNLISTED_GUEST' ? { isUnlistedGuest: true } : {}),
    });
  },
});
