import React, { forwardRef } from 'react';
import './citation-print.scss';
import { ICitationRead } from 'common/services/citation-service/citation.service.types';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import configurationStore from 'common/stores/configuration/configuration.store';
import ViolationLogo from 'assets/icons/violation.png';
import { TrafficLogVisitorType } from 'common/constans/common.constants';
import { CitationStatus, CitationType } from 'features/eiq-manage/enums/citation-status.enum';
import IntelliticketLogo from 'assets/icons/intelliticket.png';

interface ICitationPrintProps {
  citation: ICitationRead;
}

const CitationPrint = forwardRef(({ citation }: ICitationPrintProps, ref: any) => {
  const legalNotes = configurationStore.configuration?.citations.legalNotes;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
  const { state: communityState } = configurationStore.communitySettings!;
  const { communityName } = configurationStore.configuration!.default;
  const {
    type,
    sequenceNumber,
    ownerType,
    guestName,
    companyName,
    status,
    licensePlate,
    isPunishmentExtended,
    suspensionDays,
    totalSuspensionDays,
    residentName,
    approvedAt,
    externalId,
    latitude,
    longitude,
    speedLimit,
    violatingSpeed,
    occurredAt,
    licensePlatePhotosUrl,
    photosUrl,
    totalPaymentAmount,
    paymentLinkUrl,
  } = citation;

  const getLogo = () => {
    if (type === CitationType.EIQIntelliTicket) {
      return <img src={IntelliticketLogo} alt="IntelliTicket Logo"></img>;
    }
    return <img src={ViolationLogo} alt="Violation Logo"></img>;
  };

  const getTypeText = () => {
    if (type === CitationType.EIQIntelliTicket) {
      return 'IntelliTicket';
    }
    return 'Violation';
  };

  const getCitationCountText = () => {
    switch (sequenceNumber) {
      case 1:
        return 'first';
      case 2:
        return 'second';
      case 3:
        return 'third';
      default:
        return `${sequenceNumber}th`;
    }
  };

  const isGuest = () => {
    return ownerType !== TrafficLogVisitorType.Resident;
  };

  const getGuestName = () => {
    if (guestName) {
      let result = guestName;
      if (companyName) {
        result += ` (${companyName})`;
      }
      return result;
    }
    if (companyName) {
      return companyName;
    }
    return '';
  };

  const getRegisteredTo = () => {
    if (isGuest()) {
      return getGuestName();
    }
    return residentName;
  };

  const getMainText = () => {
    if (status === CitationStatus.Warning) {
      if (isGuest()) {
        return `A guest associated with your property was recorded speeding in the community using vehicle tag ${licensePlate}.`;
      } else {
        return `We are writing to notify you that your vehicle with tag ${licensePlate} was recorded speeding within the community. Here are the details of the incident and the associated consequences. This is a formal warning, and further violations will result in additional penalties.`;
      }
    }

    if (type === CitationType.EIQViolation) {
      if (isGuest()) {
        if (isPunishmentExtended) {
          return `A guest associated with your property has received a ${getCitationCountText()} citation in vehicle tag ${licensePlate}. Your transponder suspension has been extended by ${suspensionDays} additional days.`;
        } else {
          return `A guest associated with your property was recorded driving significantly over the speed limit in vehicle tag ${licensePlate}. As a result, your transponder has been suspended for ${suspensionDays} days effective immediately.`;
        }
      } else {
        if (isPunishmentExtended) {
          return `Your vehicle with tag ${licensePlate} has received a ${getCitationCountText()} citation. Due to repeated violations, your transponder suspension has been extended by ${suspensionDays} additional days.`;
        } else {
          return `We are writing to notify you that your vehicle with tag ${licensePlate} was recorded driving at a speed significantly over the community’s speed limit. Due to the nature of this violation, your transponder has been suspended for ${totalSuspensionDays} days effective immediately.`;
        }
      }
    } else {
      if (isGuest()) {
        if (sequenceNumber === 1) {
          return `A guest associated with your property was recorded speeding in the community using vehicle tag ${licensePlate}.`;
        } else if (sequenceNumber === 2) {
          return `A guest associated with your property has received a second speeding citation in vehicle tag ${licensePlate}. A $${totalPaymentAmount} fine has been issued.`;
        } else if (sequenceNumber === 3) {
          return `A guest associated with your property has received a third speeding citation in vehicle tag ${licensePlate}. A $${totalPaymentAmount} fine has been issued`;
        } else {
          return `A guest associated with your property has received a ${getCitationCountText()} speeding citation in vehicle tag ${licensePlate}. A $${totalPaymentAmount} fine has been issued.`;
        }
      } else {
        if (sequenceNumber === 1) {
          return `Your vehicle with tag ${licensePlate} was recorded driving significantly over the speed limit. A $${totalPaymentAmount} fine has been issued.`;
        } else if (sequenceNumber === 2) {
          return `Your vehicle with tag ${licensePlate} was recorded speeding within the community. This marks your second speeding citation, and a $${totalPaymentAmount} fine has been issued.`;
        } else if (sequenceNumber === 3) {
          return `Your vehicle with tag ${licensePlate} has received a third speeding citation within the community. A $${totalPaymentAmount} fine has been issued. Further citations will result in suspending your transponders.`;
        } else {
          return `Your vehicle with tag ${licensePlate} has received a ${getCitationCountText()} speeding citation within the community. A $${totalPaymentAmount} fine has been issued.`;
        }
      }
    }
  };

  const getConsequenceText = () => {
    if (type === CitationType.EIQViolation) {
      if (status === CitationStatus.Warning) {
        return 'This is a warning. A second speeding violation will result in the suspension of your transponder for 14 days.';
      }

      if (isPunishmentExtended) {
        const dateFrom = dayjs.utc(approvedAt).local().format('MM/DD/YYYY');
        return `${suspensionDays} additional days added to transponder suspension. With your previous suspension, your transponder is suspended a total of ${totalSuspensionDays} days from ${dateFrom}.`;
      }

      return `Your transponder has been suspended for ${totalSuspensionDays} days, starting immediately.`;
    } else {
      if (status === CitationStatus.Warning) {
        return `This is a warning. A second speeding violation will result in a fine.`;
      }
      return (
        <span>
          A ${totalPaymentAmount} fine has been issued. (<a href={paymentLinkUrl!}>Pay Now</a>)
        </span>
      );
    }
  };

  return (
    <div className="citation-print">
      <div className="citation-print-body" ref={ref}>
        <div className="header">
          <div className="title">{communityName} Community</div>
          <div className="title-info">
            <div className="ticket">Ticket#: {externalId}</div>
            <div className="date">Date: {dayjs(occurredAt).format('MM/DD/YYYY h:mmA')}</div>
          </div>
        </div>
        <div className="info">
          <div className="column violation1">
            <div>{getMainText()}</div>
            <div className="field">
              <span>
                <span className="bold">Citation Count: </span>
                <span className="orange bold">{getCitationCountText() + ' citation' + (isGuest() ? ' (Guest)' : '')}</span>
              </span>
              <br />
              <span>
                <span className="bold">Consequences: </span>
                <span>{getConsequenceText()}</span>
              </span>
            </div>
          </div>
          <div className="column">
            <div className="bold">INCIDENT DETAILS</div>
            <div className="violation-field">
              License Plate:
              <span className="bold"> {licensePlate}</span>
            </div>
            <div className="violation-field">
              Registered to:
              <span className="bold"> {getRegisteredTo()}</span>
            </div>
            <div className="violation-field">
              Speed Limit:
              <span className="bold green"> {speedLimit} mph</span>
            </div>
            <div className="violation-field">
              Recorded Speed:
              <span className="bold orange"> {violatingSpeed} mph</span>
            </div>
            <div className="violation-field">
              Date & Time:
              <span className="bold"> {dayjs(occurredAt).format('MM/DD/YYYY h:mmA')}</span>
            </div>
          </div>
        </div>
        <div className="photos">
          <div className="header violation">CAPTURE EVIDENCE</div>
          <div className="images">
            {photosUrl.map((item, index) => {
              return <img key={`citation-img-${index}`} src={item} alt=""></img>;
            })}
          </div>
          <div className="sub-header violation">
            {type === CitationType.EIQIntelliTicket && <div>Location of the Violation</div>}
            <div>Closeup of the License Plate</div>
          </div>
          <div className="license-images violation">
            {type === CitationType.EIQIntelliTicket && (
              <div className="location">
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=16&size=400x400&markers=color:red|label:S|${citation.latitude},${citation.longitude}&key=${googleMapsApiKey}`}
                  alt=""></img>
              </div>
            )}
            <div className="img" style={type === CitationType.EIQIntelliTicket ? { borderLeft: '2px solid #000000' } : {}}>
              <img src={licensePlatePhotosUrl[0]} alt="violation img"></img>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="violation-text">
            This citation is issued by the <span className="italic">{communityName} Homeowner’s Association (HOA)</span> as part of our
            ongoing efforts to maintain safety and compliance within our community. Speeding poses a significant risk to residents,
            particularly children and pedestrians.
          </div>
          <div className="violation-text">
            We appreciate your cooperation in maintaining a safe and secure community for all residents. Should you have any questions or
            concerns, please do not hesitate to reach out with the citation <span className="bold">#{externalId}</span>.
          </div>
          <div className="violation-text">
            <span className="bold">Sincerely,</span> <br />
            <span>{communityName} Homeowner’s Association</span>
          </div>
          <div className="legal-notice">
            <span className="bold">Legal Notice</span> <br />
            {legalNotes}
          </div>
          <div className="violation-logo">
            {getLogo()}
            <span className="italic">
              This citation is from the association board via EIQ-{getTypeText()} Enforcement System for which a fine and/or transponder
              suspension has been levied as provided for by the {communityState} statutes.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default observer(CitationPrint);
