import { Card, Col, Form, Input, Row, Space, Switch } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import configurationService from 'common/services/configuration-service/configuration.service';
import { ISystemConfigurationUpdate, OwnershipTypeConfiguration } from 'common/services/configuration-service/configuration.service.types';
import { notification } from 'common/utils/notification';
import configurationStore from 'common/stores/configuration/configuration.store';
import { useEffect } from 'react';
import { camelCaseToRegularForm } from 'common/helpers/camel-case-to-regular-form.helper';
import './guest-limitations.scss';

export const GuestLimitations = () => {
  const [form] = Form.useForm();
  const initData = configurationStore.configuration?.guestLimitations;

  useEffect(() => {
    if (form && initData) {
      form.setFieldsValue({
        permanentGuestsLimitation: initData.permanentGuestsLimitation,
        guestVisitDaysLimitation: initData.guestVisitDaysLimitation,
        maximumGuestValidityLimitation: initData.maximumGuestValidityLimitation,
        maximumStartDateAheadLimitation: initData.maximumStartDateAheadLimitation,
        ...Object.fromEntries(initData.ownershipTypesConfiguration.map((c) => [c.type, c.isLimited])),
      });
    }
  }, [form, initData]);

  let timeoutId: any;

  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      form.submit();
    }, 1500);
  };

  const onFinish = async (values: any) => {
    const ownershipTypesConfiguration: OwnershipTypeConfiguration[] = Object.keys(values)
      .filter((key) => initData?.ownershipTypesConfiguration.some((c) => c.type === key))
      .map((key) => ({ type: key, isLimited: values[key] }));

    const model: ISystemConfigurationUpdate = {
      citations: null,
      serviceHours: null,
      default: null,
      guestLimitations: {
        permanentGuestsLimitation: Number(values.permanentGuestsLimitation),
        guestVisitDaysLimitation: Number(values.guestVisitDaysLimitation),
        maximumGuestValidityLimitation: Number(values.maximumGuestValidityLimitation),
        maximumStartDateAheadLimitation: Number(values.maximumStartDateAheadLimitation),
        ownershipTypesConfiguration: ownershipTypesConfiguration,
      },
    };

    const result = await configurationService.updateSystemConfiguration(model);
    configurationStore.setConfiguration(result);
    notification.success({
      message: 'Configurations successfully updated',
      duration: 5,
    });
  };
  return (
    <FormWrapper form={form} onValuesChange={handleFormValuesChange} onFinish={onFinish} layout="vertical">
      <Card className="eiq-card eiq-card-border eiq-configuration">
        <Row className="eiq-card-header">
          <Col span={24}>
            <h1 className="h1">Guest Limitations</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={16}>
            <h3>Limitation Options</h3>
            <Space className="full-width">
              <Form.Item name="permanentGuestsLimitation" label="Number of permanent guests a resident can have">
                <Input type="number" min={0} />
              </Form.Item>
            </Space>
            <Space className="full-width">
              <Form.Item name="maximumGuestValidityLimitation" label="Maximum days allowed for guest validity (days)">
                <Input type="number" min={1} max={365} />
              </Form.Item>
            </Space>
            <Space className="full-width">
              <Form.Item
                name="maximumStartDateAheadLimitation"
                label="Days further for resident to be able to add a DateRange guest (days)">
                <Input type="number" min={0} max={365} />
              </Form.Item>
            </Space>
            <Space className="full-width">
              <Form.Item
                name="guestVisitDaysLimitation"
                label="Limitations on the number of guest visits per year (days)"
                style={{ display: 'none' }}>
                <Input type="number" min={1} max={365} />
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={8}>
            <div className="guest-options-container">
              <h3>Choose which resident types will be affected by these options:</h3>
              {initData?.ownershipTypesConfiguration.map((configuration) => (
                <div className="guest-options-block">
                  <Form.Item name={configuration.type} valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  <span>{camelCaseToRegularForm(configuration.type)}</span>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Card>
    </FormWrapper>
  );
};
