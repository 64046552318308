import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import residentStore from '../../../../../stores/resident.store';
import { vehicleInitialColumns } from './vehicle-list.config';
import { Button, Col, Row, Space } from 'antd';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import TableBase from 'common/components/table/table-base';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnVisibility } from 'common/components/table/filters/column-visibility/column-visibility';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import AddEditVehicleModal from './add-edit-vehicle-modal/add-edit-vehicle-modal';
import dayjs from 'dayjs';
import { IAddEditVehicleModal } from './vehicle-list.types';
import { VehicleStatusEnum } from 'common/enums/vehicle-status.enum';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import EiqPrint from 'features/eiq-manage/pages/eiq-manage-residents/components/info-block/print/eiq-print';
import { vehiclePrintColumns } from 'features/eiq-manage/pages/eiq-manage-residents/components/info-block/print/eiq-print.config';
import { IVehicleRead } from '../../../../../../../common/services/residents-service/residents.service.types';
import DeleteVehicleModal from './delete-vehicle-modal/delete-vehicle-modal';
import { IConfirmDeleteVehicleProps } from './delete-vehicle-modal/delete-vehicle-modal.types';
import configurationStore from 'common/stores/configuration/configuration.store';
import featureStore from 'common/stores/features/feature.store';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { notification } from 'common/utils/notification';
import residentsService from 'common/services/residents-service/residents.service';
import {
  filterColumnsByPersonalization,
  getTablePersonalizationColumnsData,
  getTablePersonalizationSortData,
  personalizationDefaultTaleSort,
} from 'common/components/table/table-helpers';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';
import { getVehicleRowClassName } from 'common/helpers/vehicles.helper';

const TABLE_NAME = 'vehicleList';

export const VehicleList = observer(() => {
  const inputRef = useRef<any>(null);
  const defaultLicenseState = configurationStore.configuration?.default.defaultLicenseState;

  let columns = vehicleInitialColumns;

  if (!featureStore.isCardAccessEnabled) {
    columns = columns.filter((i) => i.key !== 'isNoTransponder');
  }

  if (!featureStore.isCA3000CardClassesEnabled) {
    columns = columns.filter((i) => i.key !== 'cardClass');
  }

  if (!featureStore.isCA3000ExpirationsEnabled) {
    columns = columns.filter((i) => i.key !== 'validityPeriod');
  }

  // Table config
  const tableControls = useTableControls({
    clientSide: true,
    data: residentStore.vehicles,
    initialColumns: filterColumnsByPersonalization(columns, TABLE_NAME),
    defaultSort: personalizationDefaultTaleSort(TABLE_NAME),
    onUpdateColumns: async (columns: any[], sortBy: any) => {
      await userPersonalizationStore.updateTableColumnsPersonalization({
        tableName: TABLE_NAME,
        tableColumns: getTablePersonalizationColumnsData(columns),
        tableSort: getTablePersonalizationSortData(sortBy),
      });
    },
  });

  const addVehicleModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const residentId = residentStore.residentId;
      const address = residentStore.currentAddress;

      if (!address || !residentId) {
        console.error(`Resident is not selected`);
        return;
      }

      const defaultFacilityCode = configurationStore.defaultForVehicle;

      const initData: IAddEditVehicleModal = {
        id: null,
        facilityCode: defaultFacilityCode || null,
        deviceId: null,
        color: '',
        make: '',
        year: dayjs().year(),
        model: '',
        licenseNumber: '',
        licenseState: defaultLicenseState ?? '',
        rentStartDate: null,
        rentEndDate: null,
        status: VehicleStatusEnum.Approved,
        parkSpaceNumber: null,
        registrationAddress: null,
        additionalInformation: null,
        owner: '',
        validFrom: null,
        validTo: null,
        residentId: residentId!,
        address: address,
        isDeactivated: false,
        deactivationReason: null,
        isNoTransponder: false,
        cardClass: null,
      };

      setModalInitData(initData);
    },
    onClose: (isSuccess, vehicle: IVehicleRead) => {
      if (isSuccess) {
        residentStore.addVehicle(vehicle);
      }
    },
  });

  const editVehicleModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: IVehicleRead = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }

      const editVehicleModel: IAddEditVehicleModal = {
        id: selectedRow.id,
        facilityCode: selectedRow.facilityCode,
        deviceId: selectedRow.deviceId,
        color: selectedRow.color,
        make: selectedRow.make,
        year: selectedRow.year,
        model: selectedRow.model,
        licenseNumber: selectedRow.licenseNumber,
        licenseState: selectedRow.licenseState,
        rentStartDate: selectedRow.rentStartDate ? dayjs(selectedRow.rentStartDate) : null,
        rentEndDate: selectedRow.rentEndDate ? dayjs(selectedRow.rentEndDate) : null,
        status: selectedRow.status,
        parkSpaceNumber: selectedRow.parkSpaceNumber,
        registrationAddress: selectedRow.registrationAddress,
        additionalInformation: selectedRow.additionalInformation,
        owner: selectedRow.owner,
        validFrom: selectedRow.validFrom ? dayjs(selectedRow.validFrom) : null,
        validTo: selectedRow.validTo ? dayjs(selectedRow.validTo) : null,
        residentId: residentStore.residentId!,
        address: residentStore.currentAddress!,
        isDeactivated: selectedRow.isDeactivated,
        deactivationReason: selectedRow.deactivationReason,
        isNoTransponder: selectedRow.isNoTransponder,
        cardClass: selectedRow.cardClass,
      };

      setModalInitData(editVehicleModel);
    },
    onClose: (isSuccess, vehicle: IVehicleRead) => {
      if (isSuccess) {
        const selectedRow: IVehicleRead = tableControls.getSelectedRow();
        residentStore.updateVehicle({ ...vehicle, isShared: selectedRow?.isShared });
        tableControls.updateSelectedRow({ ...vehicle, isShared: selectedRow?.isShared });
      }
    },
  });

  const deleteVehicleModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: IVehicleRead = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }

      const data: IConfirmDeleteVehicleProps = {
        vehicle: selectedRow,
        residentId: residentStore.selectedResidentAddress.resident.id,
        addressId: residentStore.selectedResidentAddress.address.id,
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        const selectedRow: IVehicleRead = tableControls.getSelectedRow();
        residentStore.updateVehicle({ ...selectedRow, status: VehicleStatusEnum.Deleted });
        tableControls.updateSelectedRow({ ...selectedRow, status: VehicleStatusEnum.Deleted });
      }
    },
  });

  const printRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleFileChange = (event: any) => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    vehicleService
      .importVehicles(residentStore.residentId!, residentStore.addressId!, event.target.files[0])
      .then((result) => {
        if (!result.importedCount) {
          notification.error({ message: 'Import failed. Please refer to CSV file requirements' });
          return;
        }
        notification.success({ message: 'Vehicles were imported successfully' });

        if (result?.skipped?.length) {
          notification.warning({ message: `${result.skipped.length} records were skipped` });
        }
        residentsService.getResidentDetails(residentStore.residentId!).then((data) => {
          residentStore.setResident(data);
        });
      })
      .catch((error) => {
        notification.error({ message: error.details[0].errors[0] });
      });
  };

  return (
    <div>
      <EiqPrint
        firstName={residentStore.selectedResident?.firstName}
        lastName={residentStore.selectedResident?.lastName}
        address={residentStore.selectedResidentAddress?.address}
        data={residentStore.vehicles}
        ref={printRef}
        columns={vehiclePrintColumns}
      />
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                adaptiveSearch={true}
                enableProgressiveSearch={true}
                placeholder="Search by owner, facility code, device ID, address of registration"
                rulesForColumn={{
                  owner: FilterTypesEnum.Contains,
                  facilityCode: FilterTypesEnum.Contains,
                  deviceId: FilterTypesEnum.Contains,
                  registrationAddress: FilterTypesEnum.Contains,
                }}
                rightSide={
                  <Space size={8}>
                    <Button type="default" icon={<PrintIcon />} size={'middle'} onClick={handlePrint} className="mobile-btn">
                      Print
                    </Button>
                    <Button
                      type="default"
                      onClick={() => {
                        inputRef.current?.click();
                      }}
                      icon={<ImportIcon />}
                      size={'middle'}
                      className="mobile-btn">
                      Import
                    </Button>
                    <ColumnVisibility isColumnReorderAllow={true} />
                    <Button
                      type="default"
                      icon={<DeleteIcon stroke="#505762" />}
                      size="middle"
                      className="mobile-btn"
                      onClick={deleteVehicleModal.showModal}
                      disabled={!tableControls.getSelectedRow() || tableControls.getSelectedRow().status === VehicleStatusEnum.Deleted}>
                      Delete
                    </Button>
                    <Button
                      type="default"
                      icon={<EditIcon />}
                      size="middle"
                      className="mobile-btn"
                      onClick={editVehicleModal.showModal}
                      disabled={!tableControls.getSelectedRow()}>
                      Edit
                    </Button>
                    <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={addVehicleModal.showModal} className="mobile-btn">
                      Add
                    </Button>
                  </Space>
                }
              />
            </Col>
          </Row>
          <TableBase selectFirstRowByDefault rowClassName={getVehicleRowClassName} />
        </div>
      </TableContextProvider>

      <AddEditVehicleModal
        isEdit={true}
        initData={editVehicleModal?.initData}
        title="Edit"
        isOpen={editVehicleModal?.isOpenModal}
        onClose={editVehicleModal?.hideModal}
      />
      <AddEditVehicleModal
        isEdit={false}
        initData={addVehicleModal?.initData}
        title="Add"
        isOpen={addVehicleModal?.isOpenModal}
        onClose={addVehicleModal?.hideModal}
      />
      <DeleteVehicleModal
        isEdit={false}
        initData={deleteVehicleModal?.initData}
        title="Delete"
        isOpen={deleteVehicleModal?.isOpenModal}
        onClose={deleteVehicleModal?.hideModal}
      />
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        onClick={() => {
          inputRef.current.value = null;
        }}
      />
    </div>
  );
});
