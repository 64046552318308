import { ServerClient } from 'common/utils/http/http-clients';
import { IReportData } from './reports.service.types';

class ReportsService {
  async getReportData(): Promise<IReportData> {
    const res = await ServerClient.get<IReportData>(`/insight/power-bi/embed-data`);
    return res?.data;
  }
}

export const reportsService = new ReportsService();
export default reportsService;
