import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Space, Switch, Tag } from 'antd';
import { IModal } from 'common/models/model.interface';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { notification } from 'common/utils/notification';
import { observer } from 'mobx-react-lite';
import residentsService from 'common/services/residents-service/residents.service';
import { IGuestRead, IResidentAddressBaseRead, IResidentAddresses } from 'common/services/residents-service/residents.service.types';
import citationService from 'common/services/citation-service/citation.service';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import SelectState from 'common/components/form-elements/select-state/select-state';
import { addEditVehicleValidation } from '../../eiq-manage-residents/components/info-block/vehicles/add-edit-vehicle-modal/add-edit-vehicle-modal.validation';
import { IEditCitationLicensePlate } from 'common/services/citation-service/citation.service.types';
import { GuestType } from 'common/enums/guest-type.enum';
import { guestTypeColorMap } from 'common/list-configs/guest-list.config';
import { getGuestTypeValue } from 'common/helpers/guest.helper';
import { citationOwnerTypeOptions } from '../citations.config';
import { IResponseList } from 'common/models/response-list.interface';
import { debounce } from 'lodash';

interface IEditCitationModalProps {
  citationId: number;
  licenseState: string | null;
  licenseNumber: string | null;
  ownerType?: number;
}

const EditCitationModal: FC<IModal<IEditCitationModalProps, any>> = observer(({ isOpen, onClose, title, initData }) => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState<any[]>([]);
  const [isSearch, setIsSearch] = useState(false);

  const onSearch = debounce((value?: string) => {
    handleSearch(value);
  }, 350);

  const handleSearch = (newValue?: string) => {
    let filter = newValue ? `residentLicensePlateNumber:*${newValue} OR guestLicensePlateNumber:*${newValue}*` : null;
    residentsService.getResidentAddressesDetails(null, filter, null).then((result: IResponseList<IResidentAddressBaseRead>) => {
      const search = newValue ?? '';
      const res = getOptions(result.items, search);
      setOptions(res);
    });
  };

  const hideModal = (isSuccess: boolean) => {
    form.resetFields();
    notification.destroy();
    setOptions([]);
    if (onClose) {
      onClose(isSuccess);
      setIsSearch(false);
    }
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
    }
  }, [isOpen, form, initData]);

  const onFinish = async (values: any) => {
    if (!initData?.citationId) {
      console.error('Citation id is empty');
      return;
    }

    let model: IEditCitationLicensePlate;
    if (isSearch) {
      model = options[values.value].item;
    } else {
      model = {
        state: values.licenseState,
        number: values.licenseNumber,
        ownerType: values.ownerType,
      };
    }
    citationService.editLicensePlate(initData.citationId, model).then((result) => {
      hideModal(true);
    });
  };

  const getResidentTag = () => {
    return (
      <Tag className="eiq-tag" color="#C1FFEA" style={{ color: '#292B2E', marginRight: 0 }}>
        Resident
      </Tag>
    );
  };

  const getGuestTypeTag = (type: GuestType) => {
    return (
      <Tag className="eiq-tag" color={guestTypeColorMap[type as GuestType]} style={{ color: '#292B2E', marginRight: 0 }}>
        {getGuestTypeValue(type)}
      </Tag>
    );
  };

  //todo :: add pagination
  const getOptions = (residents: IResidentAddressBaseRead[], search: string) => {
    const res = residents.map((item) => {
      const residentName = `${item.resident.firstName} ${item.resident.lastName}`;
      const residentId = item.resident.id;

      const residentVehicleOptions = item.vehicles
        .filter((i) => i.licenseNumber.toLowerCase().includes(search.toLowerCase()))
        .map((v) => {
          return {
            label: (
              <span>
                {residentName} {getResidentTag()} {v.licenseNumber} ({v.licenseState})
              </span>
            ),
            item: {
              residentId,
              number: v.licenseNumber,
              state: v.licenseState,
              guestId: null,
            },
          };
        });

      const residentGuestOptions = item.guests.map((g) => {
        const licenseNumbers = g.carLicensePlates.filter((l) => l.number.toLowerCase().includes(search.toLowerCase()));

        return licenseNumbers
          .filter((clp) => clp.number?.toLowerCase().includes(search.toLowerCase()))
          .map((ln, i) => {
            return {
              label: (
                <span>
                  {residentName}, Guest: {getGuestName(g)} {getGuestTypeTag(g.type as GuestType)} {ln.number} ({ln.state})
                </span>
              ),
              item: {
                residentId,
                number: ln.number,
                state: ln.state,
                guestId: g.id,
              },
            };
          });
      });

      const flattenedArray = residentGuestOptions.filter((i) => i.length > 0).reduce((acc, curr) => acc.concat(curr), []);

      const result = [...residentVehicleOptions, ...flattenedArray];
      return result;
    });

    return res.reduce((acc, curr) => acc.concat(curr), []);
  };

  const getGuestName = (guest?: IGuestRead) => {
    if (isServiceGuest(guest?.type)) {
      return guest?.companyName;
    } else {
      return `${guest?.firstName} ${guest?.lastName}`;
    }
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} layout="vertical">
        <Space className="full-width" size={13}>
          <Form.Item name="residentSearch" label="Search" valuePropName="checked">
            <Switch
              checked={isSearch}
              onChange={(residentSearch) => {
                setIsSearch(residentSearch);
              }}
            />
          </Form.Item>
        </Space>
        {isSearch && (
          <Space className="full-width">
            <Form.Item name="value" label="Resident" rules={[{ required: true, message: 'Please select a value!' }]}>
              <Select
                placeholder="Search by license number"
                defaultActiveFirstOption={false}
                showSearch
                allowClear
                onSearch={onSearch}
                filterOption={(inputValue, option) => true}
                onDropdownVisibleChange={(isOpen) => isOpen && options?.length === 0 && onSearch()}
                options={options.map((i, index) => ({ label: i.label, value: index }))}
              />
            </Form.Item>
          </Space>
        )}
        {!isSearch && (
          <Space className="full-width">
            <Form.Item name="licenseNumber" label="License Plate" rules={addEditVehicleValidation.licenseNumber}>
              <Input name="licenseNumber" placeholder="Enter license plate" />
            </Form.Item>
            <Form.Item name="licenseState" label="License State" rules={addEditVehicleValidation.licenseState}>
              <SelectState placeholder="Select a license state" />
            </Form.Item>
            <Form.Item name="ownerType" label="Type" rules={[{ required: true, message: 'Type is required!' }]}>
              <Select options={citationOwnerTypeOptions} placeholder="Select a type" />
            </Form.Item>
          </Space>
        )}
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
});

export default React.memo(EditCitationModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
