import { GuestTypeSource } from '../enums/guest-type-source.enum';

export const guestSourceTypeMap: Record<GuestTypeSource, string> = {
  [GuestTypeSource.Undefined]: 'Undefined',
  [GuestTypeSource.User]: 'Management',
  [GuestTypeSource.SecurityOfficer]: 'Security',
  [GuestTypeSource.Resident]: 'Resident',
  [GuestTypeSource.Imported]: 'Imported',
  [GuestTypeSource.EIQVoice]: 'Resident via EIQ-Voice',
};

export const guestSourceTypeOptions = [
  { value: GuestTypeSource.User, label: guestSourceTypeMap[GuestTypeSource.User] },
  { value: GuestTypeSource.SecurityOfficer, label: guestSourceTypeMap[GuestTypeSource.SecurityOfficer] },
];
