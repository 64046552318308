import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Row } from 'antd';
import TableBase from 'common/components/table/table-base';
import { SearchFilter } from '../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../common/enums/filter-types.enum';
import { ReactComponent as AdmitIcon } from 'assets/icons/admit.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filtericon.svg';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import { ColumnFilter } from '../../../../common/components/table/filters/column-filter/column-filter';
import { ITableControls } from '../../../../common/components/table/table.types';
import eiqSecureStore from '../../stores/eiq-secure.store';
import { EIQ_SECURE_RESIDENT_LIST_SEARCH_FILTER_ID } from 'common/helpers/search-filter.helper';
import { ColumnVisibility } from 'common/components/table/filters/column-visibility/column-visibility';
import configurationStore from 'common/stores/configuration/configuration.store';

interface IEiqSecureResidentsListProps {
  handleAdmitResident: () => void;
}
export const EiqSecureResidentsList = observer(({ handleAdmitResident }: IEiqSecureResidentsListProps) => {
  const { isMobileOrTable, isDesktop } = useMedia();
  const screenHeight = window.innerHeight;
  const { admitFlow } = eiqSecureStore;

  const resetHandler = (tableContext: ITableControls<any>) => {
    tableContext.resetFilter('AND');
    tableContext.updateFilter('residentLicensePlateNumber', null, 'OR');
    tableContext.updateFilter('guestLicensePlateNumber', null, 'OR');
  };

  const filters = (
    <ColumnFilter
      width={258}
      buttonType="ghost"
      classNameContent="so-column-filter"
      buttonIcon={<FilterIcon />}
      buttonText={''}
      onResetFilters={resetHandler}
      onBeforeApply={resetHandler}
      filterConfig={[
        {
          name: 'resident_clubId',
          filterType: FilterTypesEnum.Equals,
          label: 'Club member #',
          type: 'INPUT',
        },
        {
          name: 'guestLicensePlateNumber',
          filterType: FilterTypesEnum.StartWith,
          label: 'Guest license',
          type: 'INPUT',
        },
        {
          name: 'UnknownLicense',
          label: 'Unknown license',
          type: 'INPUT',
          onChange: (filterValue, tableContext) => {
            tableContext.resetFilter('AND');
            tableContext.updateFilter('residentLicensePlateNumber', { operator: FilterTypesEnum.Contains, value: filterValue }, 'OR');
            tableContext.updateFilter('guestLicensePlateNumber', { operator: FilterTypesEnum.Contains, value: filterValue }, 'OR');
          },
        },
        {
          name: 'address_blockNumber',
          filterType: FilterTypesEnum.Equals,
          label: 'Block',
          type: 'INPUT',
        },
        {
          name: 'address_buildingNumber',
          filterType: FilterTypesEnum.Equals,
          label: 'Building',
          type: 'INPUT',
        },
        {
          name: 'address_street_community_name',
          filterType: FilterTypesEnum.StartWith,
          label: 'Community',
          type: 'INPUT',
        },
      ]}
    />
  );

  const getSearchPlaceholder = () => {
    const isIncludePin = configurationStore.configuration?.default.isShowPin;

    return `Search by first name, last name, address, phone, ${isIncludePin ? 'pin, ' : ''}club ID`;
  };

  const getRulesForColumn = () => {
    let resultl = {
      name: FilterTypesEnum.StartWith,
      address: FilterTypesEnum.StartWith,
      address_blockNumber: FilterTypesEnum.StartWith,
      address_lotNumber: FilterTypesEnum.StartWith,
      address_unit: FilterTypesEnum.StartWith,
      residentPrimaryPhone: FilterTypesEnum.StartWith,
      resident_clubID: FilterTypesEnum.StartWith,
    };

    const isIncludePin = configurationStore.configuration?.default.isShowPin;

    if (isIncludePin) {
      return { ...resultl, resident_pin: FilterTypesEnum.StartWith };
    }

    return resultl;
  };

  return (
    <>
      {(isDesktop || (isMobileOrTable && admitFlow === null)) && (
        <>
          {isDesktop && admitFlow === null && (
            <Button className="admit-resident-btn secondary-btn" icon={<AdmitIcon />} onClick={handleAdmitResident}>
              Admit resident
            </Button>
          )}

          <Row gutter={8} align="middle" className="eiq-secure-resident-list-filters">
            <Col flex="auto">
              <SearchFilter
                id={EIQ_SECURE_RESIDENT_LIST_SEARCH_FILTER_ID}
                className="full-width"
                enableProgressiveSearch={true}
                placeholder={getSearchPlaceholder()}
                suffix={filters}
                shouldFocus={eiqSecureStore.isAdmitFlowProcessed}
                rulesForColumn={getRulesForColumn()}
              />
            </Col>
            <ColumnVisibility isColumnReorderAllow={true} />
          </Row>

          <TableBase selectFirstRowByDefault minHeight={`${screenHeight * 0.2}px`} />
        </>
      )}
    </>
  );
});
