import { Button, Card, Col, Form, Row, Space, TreeSelect } from 'antd';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { SH } from 'common/components';
import React, { useEffect, useState } from 'react';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { DateRange } from 'common/components/form-elements/date-range/date-range';
import { TableContextProvider, useTableControls } from 'common/components/table/table-context';
import trafficService from '../../../../common/services/traffic-service/traffic-service';
import TableBase from 'common/components/table/table-base';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { directionOptions, reasonsOptions } from '../../constans/common.eiq-secure';
import { admittanceOptions } from 'common/constans/admittance.constants';
import { visitorTypeOptions } from 'common/constans/visitor.constants';
import { trafficInitialColumns } from './traffic-list.config';
import './traffic.scss';
import { Dayjs } from 'dayjs';
import { ITrafficForm } from './traffic.types';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import configurationStore from 'common/stores/configuration/configuration.store';
import featureStore from 'common/stores/features/feature.store';
import { FeatureName } from 'common/enums/feature-name.enum';
import { ITrafficRead } from 'common/services/traffic-service/traffic-service.types';
import { TrafficLogVisitorType } from 'common/constans/common.constants';
import { ColumnVisibility } from 'common/components/table/filters/column-visibility/column-visibility';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';
import {
  filterColumnsByPersonalization,
  getTablePersonalizationColumnsData,
  getTablePersonalizationSortData,
  personalizationDefaultTaleSort,
} from 'common/components/table/table-helpers';
import { useNotifications } from '../../../../common/context/NotificationsContext';

const directionTreeOptions = [
  {
    title: 'All',
    value: 'SelectAll',
    children: directionOptions?.map((option) => ({ title: option.label, value: option.value })),
  },
];

const admittanceTreeOptions = [
  {
    title: 'All',
    value: 'SelectAll',
    children: admittanceOptions?.map((option) => ({ title: option.label, value: option.value })),
  },
];

const visitorTypeTreeOptions = [
  {
    title: 'All',
    value: 'SelectAll',
    children: visitorTypeOptions?.map((option) => ({ title: option.label, value: option.value })),
  },
];

const TABLE_NAME = 'traffic';

export const Traffic = () => {
  const [form] = Form.useForm();

  const { isMobile } = useMedia();

  const [cameraOptions, setCameraOptions] = useState<{ title: string; value: string; children: { title: string; value: string }[] }[]>([]);
  const [showCamera, setShowCamera] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const tableControls = useTableControls({
    onFetch: trafficService.getTraffic,
    defaultSort: personalizationDefaultTaleSort(TABLE_NAME),
    initialColumns: filterColumnsByPersonalization(trafficInitialColumns, TABLE_NAME),
    onUpdateColumns: async (columns: any[], sortBy: any) => {
      await userPersonalizationStore.updateTableColumnsPersonalization({
        tableName: TABLE_NAME,
        tableColumns: getTablePersonalizationColumnsData(columns),
        tableSort: getTablePersonalizationSortData(sortBy),
      });
    },
  });

  const gateOptions = [
    {
      title: 'All',
      value: 'SelectAll',
      children: configurationStore?.configuration?.gates?.map((gate) => ({ title: gate.name, value: gate.name })),
    },
  ];

  const handleGateChange = (value: string[]) => {
    if (!showCamera) {
      return;
    }

    updateCameraOptions(value);
  };

  const updateCameraOptions = (selectedGates: string[]) => {
    let uniqueCameras = [];

    if (!selectedGates || selectedGates.length === 0 || selectedGates[0] === 'SelectAll') {
      const allCameras = configurationStore?.configuration?.gates?.flatMap((gate) => gate.cameras || []).map((camera) => camera.name);
      uniqueCameras = Array.from(new Set(allCameras));
    } else {
      const selectedGateNames = selectedGates;
      const selectedGatesData = configurationStore?.configuration?.gates?.filter((gate) => selectedGateNames.includes(gate.name));
      const cameras = selectedGatesData?.flatMap((gate) => gate.cameras || []).map((camera) => camera.name);
      uniqueCameras = Array.from(new Set(cameras));
    }

    const options = [{ title: 'All', value: 'SelectAll', children: uniqueCameras.map((camera) => ({ title: camera, value: camera })) }];
    setCameraOptions(options);

    const selectedCameras = form.getFieldValue('camera');
    if (selectedCameras && selectedCameras.length > 0 && selectedCameras[0] !== 'SelectAll') {
      const validCameraValues = uniqueCameras;
      const invalidCameras = selectedCameras.filter((camera: string) => !validCameraValues.includes(camera));

      if (invalidCameras.length > 0) {
        const newSelectedCameras = selectedCameras.filter((camera: string) => validCameraValues.includes(camera));
        form.setFieldsValue({ camera: newSelectedCameras });
      }
    }
  };

  useEffect(() => {
    const isLicensePlateRecognitionFeatureEnabled = featureStore.IsEnabled(FeatureName.LPR);

    setShowCamera(isLicensePlateRecognitionFeatureEnabled);

    let columns = trafficInitialColumns;

    if (isLicensePlateRecognitionFeatureEnabled) {
      const selectedGates = form.getFieldValue('gate');
      updateCameraOptions(selectedGates);
    } else {
      columns = trafficInitialColumns.filter((column) => column.key !== 'camera');
    }

    if (!featureStore.isFastAccessEnabled) {
      columns = trafficInitialColumns.filter((column) => column.key !== 'isEIQPassportUsed');
    }

    if (columns.length !== trafficInitialColumns.length) {
      tableControls.updateColumns(columns);
    }
  }, []);

  const onFinish = (value: ITrafficForm) => {
    // Select Tree
    if (value?.visitorType) {
      tableControls.updateFilter(
        'visitorType',
        value?.visitorType[0] === 'SelectAll' ? null : value?.visitorType?.map((value) => ({ operator: FilterTypesEnum.Equals, value })),
        'AND',
      );
    }

    if (value?.admittanceType) {
      tableControls.updateFilter(
        'admittanceType',
        value?.admittanceType[0] === 'SelectAll'
          ? null
          : value?.admittanceType?.map((value) => ({ operator: FilterTypesEnum.Equals, value })),
        'AND',
      );
    }

    if (value?.direction) {
      tableControls.updateFilter(
        'direction',
        value?.direction[0] === 'SelectAll' ? null : value?.direction?.map((value) => ({ operator: FilterTypesEnum.Equals, value })),
        'AND',
      );
    }

    if (value?.gate) {
      tableControls.updateFilter(
        'gate',
        value?.gate[0] === 'SelectAll' ? null : value?.gate?.map((value) => ({ operator: FilterTypesEnum.Equals, value })),
        'AND',
      );
    }

    if (value?.camera) {
      tableControls.updateFilter(
        'camera',
        value?.camera[0] === 'SelectAll' ? null : value?.camera?.map((value) => ({ operator: FilterTypesEnum.Equals, value })),
        'AND',
      );
    }

    if (value?.turnBackReason) {
      tableControls.updateFilter(
        'turnBackReason',
        value?.turnBackReason[0] === 'SelectAll'
          ? null
          : value?.turnBackReason?.map((value) => ({ operator: FilterTypesEnum.Equals, value })),
        'AND',
      );
    }

    // Date range
    if (value?.dateRange?.to || value?.dateRange?.from) {
      const dateFrom = value?.dateRange?.from;
      const dateTo = value?.dateRange?.to;
      const getDayStart = (date: Dayjs | undefined) => (date ? date.startOf('day').valueOf() : date);
      const getDayEnd = (date: Dayjs | undefined) => (date ? date.endOf('day').valueOf() : date);

      tableControls.updateFilter(
        'timestamp',
        [
          { operator: FilterTypesEnum.GreaterThanOrEqual, value: getDayStart(dateFrom), innerFilterType: 'AND' },
          { operator: FilterTypesEnum.LessThanOrEqual, value: getDayEnd(dateTo) },
        ],
        'AND',
      );
    }
  };

  const getRowClassName = (rowIndex: number, rowData: ITrafficRead): string => {
    if (
      rowData.camera &&
      rowData.userName === 'LPR' &&
      (rowData.visitorType === TrafficLogVisitorType.UnlistedGuest || !rowData.visitorType)
    ) {
      return 'unlisted-guest-from-lrp';
    }
    return '';
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <div className="eiq-secure-traffic-page">
      <Row gutter={16}>
        <Col className="page-header">
          <SH.HotkeyLink
            to={'/eiq-secure'}
            className="go-back"
            label={
              <>
                <BackIcon />
                <span>Home</span>
              </>
            }
          />
        </Col>
      </Row>
      <Row gutter={16} className="traffic-content">
        <Col flex="auto">
          <Card className="eiq-card eiq-card-border" style={{ width: '100%' }}>
            <FormWrapper form={form} onFinish={onFinish} initialValues={initialValues} layout="vertical">
              <Row justify="space-between" className="content-title">
                <Space size={12}>
                  <h2 className="main-title">Traffic records filter</h2>
                </Space>
                {!isMobile && (
                  <Button htmlType="submit" type="primary" className="css-primary-brn" icon={<ArrowRight />}>
                    Run Filter
                  </Button>
                )}
              </Row>

              <Row gutter={{ lg: 32, md: 16, xs: 0 }}>
                <Col xs={24} lg={8}>
                  <Form.Item name="dateRange">
                    <DateRange name="dateRange" label="Date Range"></DateRange>
                  </Form.Item>
                </Col>

                <Col xs={24} lg={8}>
                  <Form.Item name="visitorType" label="Visitor types">
                    <TreeSelect
                      popupClassName="eiq-select-days so-dropdown"
                      treeDefaultExpandAll={true}
                      treeCheckable={true}
                      treeData={visitorTypeTreeOptions}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                      maxTagCount={1}
                      filterTreeNode={(search, item) => {
                        return (item?.title as string)?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0;
                      }}
                      showSearch={true}
                      placeholder="All"
                    />
                  </Form.Item>

                  <Form.Item name="admittanceType" label="Admittance">
                    <TreeSelect
                      popupClassName="eiq-select-days so-dropdown"
                      treeDefaultExpandAll={true}
                      treeCheckable={true}
                      treeData={admittanceTreeOptions}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                      maxTagCount={1}
                      filterTreeNode={(search, item) => {
                        return (item?.title as string)?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0;
                      }}
                      showSearch={true}
                      placeholder="All"
                    />
                  </Form.Item>

                  <Form.Item name="turnBackReason" label="Reason for turnback">
                    <TreeSelect
                      popupClassName="eiq-select-days so-dropdown"
                      treeDefaultExpandAll={true}
                      treeCheckable={true}
                      treeData={reasonsOptions}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                      maxTagCount={1}
                      filterTreeNode={(search, item) => {
                        return (item?.title as string)?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0;
                      }}
                      showSearch={true}
                      placeholder="All reasons"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={8}>
                  <Form.Item name="gate" label="Gate">
                    <TreeSelect
                      popupClassName="eiq-select-days so-dropdown"
                      treeDefaultExpandAll={true}
                      treeCheckable={true}
                      treeData={gateOptions}
                      onChange={handleGateChange}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                      maxTagCount={1}
                      filterTreeNode={(search, item) => {
                        return (item?.title as string)?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0;
                      }}
                      showSearch={true}
                      placeholder="All"
                    />
                  </Form.Item>

                  {showCamera && (
                    <Form.Item name="camera" label="Camera">
                      <TreeSelect
                        popupClassName="eiq-select-days so-dropdown"
                        treeDefaultExpandAll={true}
                        treeCheckable={true}
                        treeData={cameraOptions}
                        showCheckedStrategy={TreeSelect.SHOW_PARENT}
                        maxTagCount={1}
                        filterTreeNode={(search, item) => {
                          return (item?.title as string)?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0;
                        }}
                        showSearch={true}
                        placeholder="All"
                      />
                    </Form.Item>
                  )}

                  <Form.Item name="direction" label="Direction">
                    <TreeSelect
                      popupClassName="eiq-select-days so-dropdown"
                      treeDefaultExpandAll={true}
                      treeCheckable={true}
                      treeData={directionTreeOptions}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                      maxTagCount={1}
                      filterTreeNode={(search, item) => {
                        return (item?.title as string)?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0;
                      }}
                      showSearch={true}
                      placeholder="All"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="eiq-secure-traffic-result">Search in Log</Row>

              {isMobile && (
                <div className="mobile-footer">
                  <Button htmlType="submit" type="primary" className="css-primary-brn w-100" icon={<ArrowRight />}>
                    Run Filter
                  </Button>
                </div>
              )}
            </FormWrapper>
            <TableContextProvider controls={tableControls}>
              <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
                <Col flex="auto">
                  <SearchFilter
                    value={searchValue}
                    onChange={(e) => handleSearchChange(e.target.value)}
                    placeholder="Search by resident’s name, address, guest’s name, license plate"
                    rulesForColumn={{
                      residentName: FilterTypesEnum.Contains,
                      address: FilterTypesEnum.Contains,
                      guestName: FilterTypesEnum.Contains,
                      carLicensePlate: FilterTypesEnum.Contains,
                    }}
                  />
                </Col>
                <ColumnVisibility isColumnReorderAllow={true} />
              </Row>
              <TableBase minHeight={'200px'} rowClassName={getRowClassName} />
            </TableContextProvider>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const initialValues = {};
