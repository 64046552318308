import { ServerClient } from 'common/utils/http/http-clients';
import {
  IMoveResident,
  IResidentAddressListResponse,
  IResidentAddresses,
  IResidentCreate,
  IResidentDetails,
  IResidentResponse,
  IResidentUpdate,
  ICreateAddress,
  IResidentAddressBaseRead,
} from './residents.service.types';
import { IAddressOccupant } from '../guest-service/guest.service.types';
import { IParamsLimit } from 'common/models/params-limit.interface';
import { IResponseList } from 'common/models/response-list.interface';

class ResidentsService {
  async createResident(data: IResidentCreate): Promise<IResidentCreate> {
    const res = await ServerClient.post<IResidentCreate>('/residents', data);
    return res?.data;
  }

  async updateResident(data: IResidentUpdate): Promise<IResidentUpdate> {
    const res = await ServerClient.patch<IResidentUpdate>(`/residents/${data.id}`, data);
    return res?.data;
  }

  async getResidents(
    pageLimit: IParamsLimit | null | undefined,
    filter: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<IResidentResponse> {
    const res = await ServerClient.get<IResidentResponse>('/residents', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async getResidentAddressesDetails(
    pageLimit: any,
    filter: string | null | undefined,
    sort: string | null | undefined,
    search: string | null | undefined = '',
  ): Promise<IResponseList<IResidentAddressBaseRead>> {
    const res = await ServerClient.get<IResponseList<IResidentAddressBaseRead>>('/residents/addresses/details', {
      params: {
        filter,
        ...pageLimit,
        sort,
        search,
      },
    });
    return res?.data;
  }

  async getResidentAddresses(
    pageLimit: any,
    filter: string | null | undefined,
    sort: string | null | undefined,
    search: string | null | undefined = '',
  ): Promise<IResidentAddressListResponse> {
    const res = await ServerClient.get<IResidentAddressListResponse>('/residents/addresses', {
      params: {
        filter,
        ...pageLimit,
        sort,
        search,
      },
    });
    return res?.data;
  }

  async getResidentDetails(residentId: number | null): Promise<IResidentDetails> {
    const res = await ServerClient.get<IResidentDetails>(`/residents/${residentId}`);
    return res?.data;
  }

  async getResidentsAddresseDetails(residentId: number | null, addressId: number | null): Promise<IResidentAddresses> {
    const res = await ServerClient.get<IResidentAddresses>(`/residents/${residentId}/addresses/${addressId}`);
    return res?.data;
  }

  async deleteResident(residentId: number): Promise<any> {
    const res = await ServerClient.delete(`/residents/${residentId}`);
    return res?.data;
  }

  async deleteResidentAddress(
    residentId: number | null,
    addressId: number | null,
    newOwnerId?: number,
    newGuestOwnerId?: number,
  ): Promise<IResidentAddresses> {
    const res = await ServerClient.delete<IResidentAddresses>(`/residents/${residentId}/addresses/${addressId}`, {
      data: {
        newOwnerId: newOwnerId,
        newGuestOwnerId: newGuestOwnerId,
      },
    });
    return res?.data;
  }

  async addResidentAddress(residentId: number | null, data: ICreateAddress): Promise<IResidentAddresses> {
    const res = await ServerClient.post<IResidentAddresses>(`/residents/${residentId}/addresses`, data);
    return res?.data;
  }

  async moveResidentToAddress(residentId: number, addressFromId: number, data: IMoveResident) {
    const res = await ServerClient.put<any>(`/residents/${residentId}/addresses/${addressFromId}/move`, data);
    return res?.data;
  }

  async sendWelcomeEmail(residentId: number) {
    const res = await ServerClient.put<void>(`/residents/${residentId}/send-welcome-email`);
    return res?.data;
  }

  async getOccupants(residentId: number): Promise<IAddressOccupant[]> {
    const res = await ServerClient.get<IAddressOccupant[]>(`/residents/${residentId}/occupants`);
    return res?.data;
  }

  async recover(residentId: number) {
    const res = await ServerClient.post<void>(`/residents/${residentId}/recover`);
    return res?.data;
  }

  async generatePin() {
    const res = await ServerClient.post<string>('/residents/actions/generate-pin');
    return res?.data;
  }
}

export const residentsService = new ResidentsService();
export default residentsService;
