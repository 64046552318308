export enum AdmittanceType {
  Undefined,
  Approved,
  TurnBack,
  Readmission,
  Credential,
  CredentialDenied,
  Unidentified,
  PlateScan,
}
