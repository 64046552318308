import { Card, Col, Row, Space } from 'antd';
import { IncidentReportList } from 'common/components/incident-report/incident-report-list/incident-report-list';
import { PermissionEnum } from 'common/enums/permission.enum';
import { UserTypeEnum } from 'common/enums/user-type.enum';
import userAuthStore from 'common/stores/user-auth/user-auth.store';

export const IncidentReports = () => {
  const hasAddPermission = () => {
    return (
      userAuthStore.userType === UserTypeEnum.Admin ||
      (userAuthStore.userType === UserTypeEnum.SecurityOfficer && userAuthStore.permissions.includes(PermissionEnum.writeIncidentReport))
    );
  };

  const hasDeletePermission = () => {
    return userAuthStore.userType === UserTypeEnum.Admin;
  };

  const getBaseRoute = () => {
    return userAuthStore.userType === UserTypeEnum.SecurityOfficer ? '/eiq-secure' : '/eiq-manage';
  };

  return (
    <div className="incident-reports-page">
      <Row gutter={16}>
        <Col flex="auto">
          <Card className="eiq-card eiq-card-border" style={{ width: '100%' }}>
            <Row justify="space-between" className="content-title">
              <Space size={12}>
                <h2 className="main-title">Incident Reports</h2>
              </Space>
            </Row>
            <IncidentReportList showCreateButton={hasAddPermission()} showDeleteButton={hasDeletePermission()} baseRoute={getBaseRoute()} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
