import React from 'react';

import { INotification, useNotifications } from '../../../../../common/context/NotificationsContext';
import './custom-notification-item.scss';
import { ReactComponent as VerticalLine } from 'assets/icons/vertical-line.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

interface NotificationItemProps {
  notification: INotification;
  isLastNotification?: boolean;
  isPanelVisible?: boolean;
  toggleVisibility?: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  isLastNotification = false,
  notification,
  isPanelVisible,
  toggleVisibility,
}) => {
  const { removeNotification } = useNotifications();

  const { id, message, type } = notification;

  const closeNotification = () => {
    removeNotification(id);
  };

  const isCloseButtonShow = type === 'error' && !isLastNotification;

  return (
    <div className={`custom-notification ${type} ${isLastNotification ? 'last-notification' : ''}`}>
      <div className="icons">
        <VerticalLine />
        {notification.type === 'success' ? <SuccessIcon /> : <ExclamationIcon />}
      </div>
      <div className="main">
        <div className="body">
          <div className="notification">{message}</div>
          {isCloseButtonShow && (
            <div className="close-btn" onClick={closeNotification}>
              <CloseIcon />
            </div>
          )}
          {isLastNotification && (
            <span className="arrow-btn" onClick={toggleVisibility}>
              {isPanelVisible ? <UpOutlined /> : <DownOutlined />}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
