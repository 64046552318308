import React from 'react';
import { observer } from 'mobx-react-lite';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { Card, Col, Row, Space } from 'antd';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { vehicleAdminInitialColumns } from '../../vehicles.config';
import TableBase from 'common/components/table/table-base';
import { ColumnVisibility } from 'common/components/table/filters/column-visibility/column-visibility';
import featureStore from 'common/stores/features/feature.store';
import {
  filterColumnsByPersonalization,
  getTablePersonalizationColumnsData,
  getTablePersonalizationSortData,
  personalizationDefaultTaleSort,
} from 'common/components/table/table-helpers';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';
import accessCardService from 'common/services/access-card-service/access-card.service';
import { accessCardColumns } from 'features/eiq-manage/pages/eiq-manage-residents/components/info-block/access-cards/access-card-list.comfig';

const TABLE_NAME = 'all-devices';

export const AllDevices = observer(() => {
  let columns = accessCardColumns;

  if (!featureStore.isCA3000ExpirationsEnabled) {
    columns = columns.filter((i) => i.key !== 'validityPeriod');
  }
  if (!featureStore.isCA3000CardClassesEnabled) {
    columns = columns.filter((i) => i.key !== 'cardClass');
  }

  const getDefaultSort = () => {
    return (
      personalizationDefaultTaleSort(TABLE_NAME) || {
        key: 'owner',
        order: 'asc',
      }
    );
  };

  const tableControls = useTableControls({
    onFetch: accessCardService.getAll,
    initialColumns: filterColumnsByPersonalization(columns, TABLE_NAME),
    defaultSort: getDefaultSort(),
    onUpdateColumns: async (columns: any[], sortBy: any) => {
      await userPersonalizationStore.updateTableColumnsPersonalization({
        tableName: TABLE_NAME,
        tableColumns: getTablePersonalizationColumnsData(columns),
        tableSort: getTablePersonalizationSortData(sortBy),
      });
    },
  });

  return (
    <>
      <TableContextProvider controls={tableControls}>
        <Card className="eiq-card eiq-card-border full-card">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">Other Devices</h1>
            </Col>
          </Row>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                placeholder="Search by owner, device ID, card class, description, address"
                rulesForColumn={{
                  owner: FilterTypesEnum.Contains,
                  facilityCode: FilterTypesEnum.Contains,
                  rfid: FilterTypesEnum.Contains,
                  cardClass: FilterTypesEnum.Contains,
                  additionalInformation: FilterTypesEnum.Contains,
                  residentAddress_address_streetNumber: FilterTypesEnum.StartWith,
                  residentAddress_address_street_name: FilterTypesEnum.StartWith,
                }}
              />
            </Col>
            <Col>
              <Space size={8}>
                <ColumnVisibility isColumnReorderAllow={true} />
              </Space>
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} selectFirstRowByDefault />
        </Card>
      </TableContextProvider>
    </>
  );
});
