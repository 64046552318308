import { Button, DatePicker, Form, Input, Modal, Select, Space, Switch } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IModal } from 'common/models/model.interface';
import React, { FC, useEffect, useState } from 'react';
import { IAddEditVehicleModal } from '../vehicle-list.types';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { VehicleStatusEnum } from 'common/enums/vehicle-status.enum';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { ICreateVehicle, IUpdateVehicle } from 'common/services/vehicle-service/vehicle.service.types';
import { vehicleStatusMap } from 'common/constans/common.constants';
import { notification } from 'common/utils/notification';
import { addEditVehicleValidation } from './add-edit-vehicle-modal.validation';
import SelectState from 'common/components/form-elements/select-state/select-state';
import featureStore from 'common/stores/features/feature.store';
import { observer } from 'mobx-react-lite';
import configurationStore from 'common/stores/configuration/configuration.store';

const AddEditVehicleModal: FC<IModal<IAddEditVehicleModal, any>> = observer(({ isOpen, onClose, title, initData, isEdit }) => {
  const [form] = Form.useForm();

  const deactivationOptions =
    configurationStore.configuration?.vehicleDeactivationReasons.map((reason) => ({ value: reason, label: reason })) ?? [];
  const cardClassOptions = configurationStore.configuration?.cardClasses?.map((i) => ({ value: i.name, label: i.name })) ?? [];

  const hideModal = (...rest: any) => {
    notification.destroy();
    setIsRented(false);
    notification.destroy();
    form.resetFields();
    if (onClose) {
      onClose(...rest);
    }
  };

  const [isRented, setIsRented] = useState<boolean>();
  const [isDeactivated, setIsDeactivated] = useState<boolean>();
  const [isNoTransponder, setIsNoTransponder] = useState<boolean>();

  useEffect(() => {
    const isRented = !!initData?.rentEndDate && !!initData?.rentStartDate;
    setIsRented(isRented);
  }, [initData?.rentEndDate, initData?.rentStartDate]);

  useEffect(() => {
    setIsDeactivated(initData?.isDeactivated ?? false);
    setIsNoTransponder(initData?.isNoTransponder ?? false);
  }, [initData?.isDeactivated, initData?.isNoTransponder, isOpen]);

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
    }
  }, [isOpen, form, initData]);

  const { TextArea } = Input;

  const onFinish = async (values: any) => {
    const residentAddress = initData?.address;

    const vehicle: ICreateVehicle = {
      facilityCode: values.facilityCode,
      deviceId: values.deviceId,
      color: values.color,
      make: values.make,
      year: values.year,
      model: values.model,
      licenseNumber: values.licenseNumber,
      licenseState: values.licenseState,
      rentStartDate: values.rentStartDate?.format('YYYY-MM-DD') ?? null,
      rentEndDate: values.rentEndDate?.format('YYYY-MM-DD') ?? null,
      status: initData?.status ? initData.status : null,
      parkSpaceNumber: values.parkSpaceNumber,
      registrationAddress: values.registrationAddress,
      additionalInformation: values.additionalInformation,
      owner: values.owner,
      isNoTransponder: values.isNoTransponder,
      validFrom: values.validFrom?.format('YYYY-MM-DD') ?? null,
      validTo: values.validTo?.format('YYYY-MM-DD') ?? null,
      cardClass: values.cardClass,
    };

    if (values.isNoTransponder) {
      vehicle.deviceId = null;
      vehicle.facilityCode = null;
    }

    if (isEdit) {
      const vehicleToUpdate = vehicle as IUpdateVehicle;
      vehicleToUpdate.id = initData!.id!;
      vehicleToUpdate.isDeactivated = isDeactivated || false;
      vehicleToUpdate.deactivationReason = values.deactivationReason;
      vehicleToUpdate.status = values.status;

      const result = await vehicleService.updateVehicle(initData!.residentId, residentAddress!.address.id, vehicleToUpdate);
      hideModal(true, result);
    } else {
      const result = await vehicleService.createVehicle(initData!.residentId, residentAddress!.address.id, vehicle);
      hideModal(true, result);
    }
  };

  const getSaveButtonText = () => {
    if (initData?.status === VehicleStatusEnum.Deleted) {
      return 'Save & Recover';
    }
    return 'Save';
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initData} layout="vertical">
        <Space className="full-width" size={13}>
          <Form.Item name="owner" label="Owner" rules={addEditVehicleValidation.owner}>
            <Input name="owner" placeholder="Enter owner" />
          </Form.Item>
          {isEdit && (
            <Form.Item name="status" label="Status">
              <Select options={statusOptions} />
            </Form.Item>
          )}
        </Space>
        {featureStore.isCardAccessEnabled && (
          <Space className="full-width" size={13}>
            <Form.Item name="isNoTransponder" label="No Transponder" valuePropName="checked">
              <Switch
                checked={isNoTransponder}
                onChange={(isNoTransponder) => {
                  setIsNoTransponder(isNoTransponder);
                }}
              />
            </Form.Item>
          </Space>
        )}
        {!isNoTransponder && (
          <Space className="full-width" size={13}>
            <Form.Item
              name="facilityCode"
              label="Facility code"
              rules={featureStore.isCardAccessEnabled ? addEditVehicleValidation.facilityCode : undefined}>
              <Input name="facilityCode" placeholder="Enter facility code" />
            </Form.Item>
            <Form.Item
              name="deviceId"
              label="Device ID"
              rules={featureStore.isCardAccessEnabled ? addEditVehicleValidation.deviceId : undefined}>
              <Input name="deviceId" placeholder="Enter device ID" type="number" />
            </Form.Item>
          </Space>
        )}
        {isEdit && featureStore.isCardAccessEnabled && !isNoTransponder && (
          <Space className="full-width" size={13}>
            <Form.Item name="isDeactivated" label="Deactivated" valuePropName="checked">
              <Switch
                checked={isDeactivated}
                onChange={(isDeactivated) => {
                  setIsDeactivated(isDeactivated);
                }}
              />
            </Form.Item>
            {isDeactivated && (
              <Form.Item name="deactivationReason" label="Reason" rules={addEditVehicleValidation.deactivationReason}>
                <Select options={deactivationOptions} />
              </Form.Item>
            )}
          </Space>
        )}
        <Space className="full-width" size={13}>
          <Form.Item name="year" label="Year" rules={addEditVehicleValidation.year}>
            <Input name="year" placeholder="Enter year" type="number" />
          </Form.Item>
          <Form.Item name="make" label="Make" rules={addEditVehicleValidation.make}>
            <Input name="make" placeholder="Enter make" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="model" label="Model" rules={addEditVehicleValidation.model}>
            <Input name="model" placeholder="Enter model" />
          </Form.Item>
          <Form.Item name="color" label="Color" rules={addEditVehicleValidation.color}>
            <Input name="color" placeholder="Enter color" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="licenseNumber" label="License Plate" rules={addEditVehicleValidation.licenseNumber}>
            <Input name="licenseNumber" placeholder="Enter license plate" />
          </Form.Item>
          <Form.Item name="licenseState" label="License state" rules={addEditVehicleValidation.licenseState}>
            <SelectState placeholder="Select a license state" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item label="Rented" valuePropName="checked">
            <Switch
              checked={isRented}
              onChange={(checked) => {
                setIsRented(checked);
              }}
            />
          </Form.Item>
        </Space>
        {isRented && (
          <Space className="full-width" size={13}>
            <Form.Item name="rentStartDate" label="From" rules={addEditVehicleValidation.rentStartDate}>
              <DatePicker placeholder="Start date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Form.Item name="rentEndDate" label="To" dependencies={['rentStartDate']} rules={addEditVehicleValidation.rentEndDate}>
              <DatePicker placeholder="End date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
          </Space>
        )}
        {featureStore.isCA3000ExpirationsEnabled && !isNoTransponder && (
          <Space className="full-width" size={13}>
            <Form.Item name="validFrom" label="Valid from">
              <DatePicker placeholder="Start date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Form.Item name="validTo" label="Valid to" dependencies={['validFrom']} rules={addEditVehicleValidation.validTo}>
              <DatePicker placeholder="End date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
          </Space>
        )}
        {featureStore.isCA3000CardClassesEnabled && (
          <Space className="full-width" size={13}>
            <Form.Item name="cardClass" label="Card class">
              <Select options={cardClassOptions} placeholder="Select a card class" />
            </Form.Item>
          </Space>
        )}
        <Space className="full-width" size={13}>
          <Form.Item name="registrationAddress" label="Address of registration">
            <Input name="registrationAddress" placeholder="Enter address of registration" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="parkSpaceNumber" label="Park space number">
            <Input name="parkSpaceNumber" placeholder="Enter park space number" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="additionalInformation" label="Additional text information">
            <TextArea name="additionalInformation" placeholder="Enter additional text information" />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            {getSaveButtonText()}
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
});

export default React.memo(AddEditVehicleModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});

const statusOptions = [
  {
    value: VehicleStatusEnum.Approved,
    label: vehicleStatusMap[VehicleStatusEnum.Approved],
  },
  {
    value: VehicleStatusEnum.Pending,
    label: vehicleStatusMap[VehicleStatusEnum.Pending],
  },
];
