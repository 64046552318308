import React, { useState, useCallback, useRef, useEffect, useReducer, useMemo } from 'react';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import 'react-base-table/styles.css';
import './table.scss';
import { useMedia } from '../../hooks/use-media/use-media.hook';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Spin } from 'antd';
import { useTableContext } from './table-context';
import { LoadingOutlined } from '@ant-design/icons';
import { ICellRendererProps } from './table.types';

export function TableBase({
  initialColumns,
  onSelected,
  selectFirstRowByDefault,
  disableSelection,
  minHeight,
  selectableWithCheckboxes,
  headerHeight,
  rowHeight,
  fixed = true,
  wrapperClass = 'cc-table',
  rowClassName,
}: any) {
  const { isMobileOrTable } = useMedia();
  const tableContext = useTableContext();
  const { columns, updateColumns, sortBy, setSortBy, dataState } = tableContext;

  const handleRowCheckboxChange = (rowData: any, selectedRows: any) => {
    if (selectedRows.some((row: any) => row.id === rowData.id)) {
      tableContext.setSelectedRows(selectedRows.filter((el: any) => el.id !== rowData.id));
    } else {
      tableContext.setSelectedRows([rowData, ...selectedRows]);
    }
  };

  const hadleSelectAllRows = (tableData: any, selectedRows: any) => {
    if (tableData.length !== selectedRows.length) {
      tableContext.setSelectedRows(tableData);
    } else {
      tableContext.setSelectedRows([]);
    }
  };

  const checkboxColumn = {
    key: '__selection__',
    width: 60,
    fixed: 1,
    resizable: false,
    sortable: false,
    cellRenderer: (cell: ICellRendererProps<any>) => {
      const selectedRows = cell.container.props.selectedRows;
      return (
        <Checkbox
          checked={selectedRows.some((row: any) => row.id === cell.rowData.id)}
          onChange={() => handleRowCheckboxChange(cell.rowData, selectedRows)}
        />
      );
    },
    headerRenderer: (header: any) => {
      const tableData = header.container.props.data;
      const selectedRows = header.container.props.selectedRows;

      return <Checkbox checked={selectedRows.length === tableData.length} onChange={() => hadleSelectAllRows(tableData, selectedRows)} />;
    },
  };

  // const onColumnSort = ({ key, order }: any) => {
  // setSortBy({ key, order } as any);
  // then sort your data based on the column and order
  // const sortedData = [...data].sort((a: any, b: any) => {
  //   if (order === 'asc') return a[key] > b[key] ? 1 : -1;
  //   return b[key] > a[key] ? 1 : -1;
  // });
  // setData(sortedData);
  // };

  // Left right buttons to scroll
  const tableRef = useRef<any>();

  const scrollNextPrevColumn = (next = true) => {
    const virtualGrid = tableRef.current?.tableNode?.querySelector('.BaseTable__body');
    const tableColumn = tableRef.current?.tableNode?.querySelectorAll('.BaseTable__header-row .BaseTable__header-cell');
    const parentPosX = virtualGrid.getBoundingClientRect().x;
    let scrollLeft = 0;

    for (let columnHTML of tableColumn) {
      const columnRect = columnHTML.getBoundingClientRect();
      const columnPosX = columnRect.x;
      let isNextColumn = columnPosX + (next ? -2 : 2 + columnRect.width) > parentPosX;

      if (isNextColumn) {
        break;
      }
      scrollLeft += columnRect.width;
    }

    tableRef.current.scrollToLeft(scrollLeft);
  };

  const searchNextColumn = () => {
    scrollNextPrevColumn(true);
  };

  const scrollLeft = () => {
    scrollNextPrevColumn(false);
  };

  // Select row
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);

  const handleRowClick = ({ rowData }: { rowData: any }) => {
    if (disableSelection || selectableWithCheckboxes) {
      return;
    }
    setSelectedRowKey(rowData.id.toString());
    tableContext.setSelectedRow(rowData);

    if (onSelected) {
      onSelected({ ...rowData }, tableContext);
    }
  };

  // update data after refetch
  // useEffect(() => {
  //   console.log('Update data after refetch');
  //   if (selectedRow) {
  //     if (onSelected) {
  //       onSelected({ ...selectedRow }, tableContext);
  //     }
  //   }
  // }, [tableContext?.dataState?.data]);

  const rowEventHandlers = {
    onClick: handleRowClick,
  };
  const defaultRowClassName = ({ rowIndex, rowData }: { rowIndex: number; rowData: any }) => {
    let classes = `${rowIndex % 2 === 0 ? 'even-row' : 'odd-row'} ${rowData?.id?.toString() === selectedRowKey ? 'selected-row' : ''} ${
      disableSelection || selectableWithCheckboxes ? 'selection-disabled' : ''
    }`;

    if (rowClassName) {
      classes += rowClassName(rowIndex, rowData);
    }

    return classes;
  };

  // Test template for client side Search
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    // const filteredData = initialData.filter((item) => item.column1.toLowerCase().includes(event.target.value.toLowerCase()));
    // setData(filteredData);
  };

  // Autoresize debug, remove after code will be stabilized
  const [width, setWidth] = useState<number>(1);
  const [height, setHeihg] = useState();

  const Content = () => {
    return <div>ewfwef</div>;
  };

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (tableWrapperRef.current) {
      const handleResize = () => {
        const tableWrapper = tableWrapperRef.current as HTMLDivElement;
        setWidth(tableWrapper.clientWidth);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (selectFirstRowByDefault && tableContext?.dataState?.data?.length) {
      const currentSelectedRow = tableContext.getSelectedRow();
      const selectedRow = tableContext?.dataState?.data.find((i) => i.id === currentSelectedRow?.id) ?? tableContext?.dataState?.data[0];
      setSelectedRowKey(selectedRow.id.toString());
      tableContext.setSelectedRow(selectedRow);
      if (onSelected) {
        onSelected({ ...selectedRow }, tableContext);
      }
    }
  }, [tableContext?.dataState?.data]);

  useEffect(() => {
    setSelectedRowKey(tableContext.dataState.selectedRow?.id?.toString());
  }, [tableContext.dataState.selectedRow]);

  const renderEmpty = () => {
    if (dataState?.loading) return null;
    return <div className="empty-render">No data available</div>;
  };

  const onColumnResizeEnd = (item: any) => {
    const updatedColumns = columns?.map((col) => (col.key === item.column.key ? { ...col, width: item.width } : col));

    if (updatedColumns) {
      updateColumns(updatedColumns);
    }
  };

  const onColumnSort = (sort: any) => {
    tableContext.setSelectedRow(null);
    setSortBy(sort);
  };

  const renderOverlay = () => {
    const { loading, loadingMore } = dataState;

    if (loadingMore)
      return (
        <div className="loading-more-layer">
          <div className="loading-more-text">Loading More</div>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 12, color: '#FFFFFF' }} spin />} />
        </div>
      );
    if (loading)
      return (
        <div className="loading-layer">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: '#AEB5BE' }} spin />} />
        </div>
      );

    return null;
  };

  return (
    <div
      ref={tableWrapperRef}
      className={`${wrapperClass ?? ''}`}
      style={{ width: '100%', height: '100%', minHeight: minHeight ? minHeight : '300px' }}>
      {fixed && isMobileOrTable && (
        <div className="cc-column-scroll">
          <Button type="primary" shape="circle" size={'small'} icon={<LeftOutlined />} onClick={scrollLeft} />
          <Button type="primary" shape="circle" size={'small'} icon={<RightOutlined />} onClick={searchNextColumn} />
        </div>
      )}

      {/*<AutoResizer*/}
      {/*  onResize={(size) => {*/}
      {/*    setWidth(size.width);*/}
      {/*  }}>*/}
      {/*  <Content />*/}
      {/*</AutoResizer>*/}
      {/*<div style={{ width: '100%', overflow: 'auto' }}>*/}
      {/*  <BaseTable*/}
      {/*      // fixed  important when we use steak columns*/}
      {/*      ref={tableRef}*/}
      {/*      width={width}*/}
      {/*      height={600}*/}
      {/*      columns={columns}*/}
      {/*      data={data}*/}
      {/*      headerHeight={32}*/}
      {/*      rowHeight={32}*/}
      {/*      rowKey="id"*/}
      {/*      rowClassName={rowClassName}*/}
      {/*      onEndReached={handleEndReached}*/}
      {/*      onEndReachedThreshold={500}*/}
      {/*      sortBy={sortBy}*/}
      {/*      // cellClassName="sh-table__cell"*/}
      {/*      // onColumnSort={onColumnSort}*/}
      {/*      // components={{*/}
      {/*      //   SortIndicator,*/}
      {/*      // }}*/}
      {/*      rowEventHandlers={rowEventHandlers}*/}
      {/*  />*/}
      {/*</div>*/}
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            fixed={fixed} // fixed  important when we use steak columns
            ref={tableRef}
            width={isNaN(width) ? 0 : width}
            height={isNaN(height) ? 0 : height}
            columns={selectableWithCheckboxes ? [checkboxColumn, ...(columns as any)] : columns}
            onColumnResizeEnd={onColumnResizeEnd}
            data={tableContext?.dataState?.data}
            headerHeight={headerHeight ?? 32}
            rowHeight={rowHeight ?? 32}
            rowKey="id"
            rowClassName={defaultRowClassName}
            onEndReached={tableContext.isClientSide ? undefined : tableContext.fetchData}
            onEndReachedThreshold={500}
            sortBy={sortBy}
            cellClassName="cc-table__cell"
            onColumnSort={onColumnSort}
            overlayRenderer={renderOverlay}
            emptyRenderer={renderEmpty}
            components={{
              SortIndicator,
            }}
            rowEventHandlers={rowEventHandlers}
            selectedRows={tableContext.getSelectedRows()}
          />
        )}
      </AutoResizer>
    </div>
  );
}

const SortIndicator = ({ sortOrder, className }: any) => {
  const icon =
    sortOrder === 'asc' ? (
      <>
        <svg className="default-sort" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 12.5286L5.35834 9.8869L5.89044 9.3548L8 11.4606L10.1096 9.3548L10.6417 9.8869L8 12.5286ZM8 3.47144L10.6417 6.1131L10.1096 6.6452L8 4.53942L5.89044 6.6452L5.35834 6.1131L8 3.47144Z"
            fill="#505762"
          />
        </svg>

        <svg className="asc-sort" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.00006 12.5285L5.3584 9.88684L5.8905 9.35474L8.00006 11.4605L10.1096 9.35474L10.6417 9.88684L8.00006 12.5285Z"
            fill="#505762"
          />
          <path
            d="M10.6417 6.1131L8.00006 3.47144L5.3584 6.1131L5.8905 6.6452L8.00006 4.53942L10.1096 6.6452L10.6417 6.1131Z"
            fill="#2F7DF6"
          />
        </svg>
      </>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.00006 12.5285L5.3584 9.88684L5.8905 9.35474L8.00006 11.4605L10.1096 9.35474L10.6417 9.88684L8.00006 12.5285Z"
          fill="#2F7DF6"
        />
        <path
          d="M10.6417 6.1131L8.00006 3.47144L5.3584 6.1131L5.8905 6.6452L8.00006 4.53942L10.1096 6.6452L10.6417 6.1131Z"
          fill="#505762"
        />
      </svg>
    );
  return <div className={className}>{icon}</div>;
};

// const TableHeaderCell = ({ className, column }: any) => <div className={className}>Test {column.title}</div>;

export default TableBase;
