import { Button, Modal, Space } from 'antd';
import { IModal } from 'common/models/model.interface';
import React, { FC, useEffect, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import fastAccessService from 'common/services/fast-access-service/fast-access-service';
import QRCodeAdmitModal from './eiq-secure-qr-code-admit-modal';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import { observer } from 'mobx-react-lite';
import { IQRCodeAdmitModalProps } from './eiq-secure-qr-code-admit-modal.types';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { EntryType } from 'common/enums/entry-type.enum';
import guestService from 'common/services/guest-service/guest.service';
import { notification } from 'common/utils/notification';
import { IAdmitResident } from 'common/services/guest-service/guest.service.types';
import './eiq-secure-qr-code-scanner.scss';
import QRCodeScanDetector from '../eiq-secure-qr-code-scan-detector/eiq-secure-qr-code-scan-detector';
import { getCode } from 'common/helpers/eiq-passport.helper';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import { IFastAccessPassRead } from 'common/services/fast-access-service/fast-access-service.types';
import featureStore from 'common/stores/features/feature.store';
import { IGuestRead } from 'common/services/residents-service/residents.service.types';
import plateRecognitionContext from 'features/eiq-secure/stores/license-plate-recognition-context';
import successSound from 'assets/sounds/success.wav';
import errorSound from 'assets/sounds/error.wav';

const QRcodeReaderId = 'eiq-fast-access-reader-id';

const QRCodeScannerModal: FC<IModal<any, any>> = observer(({ isOpen, onClose }) => {
  const admitModal = useModal();
  const scanner = useRef<any>(null);

  const handleOnClose = () => {
    if (scanner.current) {
      if (scanner.current.isScanning) {
        scanner.current.stop().then(() => scanner.current.clear());
      } else {
        scanner.current.clear();
      }
    }
    onClose();
  };

  const hasAlert = (data: IFastAccessPassRead) => {
    const { alertMessage } = data;

    return featureStore.isFastAccessAlertsEnabled && alertMessage;
  };

  const hasRestriction = (guest: IGuestRead | null) => {
    return featureStore.isFastAccessRestrictionsEnabled && guest?.restrictions;
  };

  const playSound = (sound: any) => {
    const audio = new Audio(sound);
    audio.play();
  };

  const getDecodedTextHandle = (text: string) => {
    fastAccessService
      .getFastAccessPass(text)
      .then((result) => {
        if (!result) {
          notification.error({ message: 'Some error occurred' });
          return;
        }

        const { addressId, residentId, guest, carExpirationDate, carNumber, carState, deviceId, alertMessage } = result;
        const gate = userAuthStore.defaultGate;

        if (!carNumber || !carState || !gate || isServiceGuest(guest?.type) || hasAlert(result) || hasRestriction(guest)) {
          const initData: IQRCodeAdmitModalProps = {
            addressId: addressId,
            residentId: residentId,
            guest: guest,
            carExpirationDate: carExpirationDate,
            gate: userAuthStore.defaultGate,
            carNumber: carNumber,
            carState: carState,
            deviceId: deviceId,
            alertMessage: alertMessage,
            isEIQPassportUsed: true,
          };

          admitModal.setModalInitData(initData);
          admitModal.showModal();
        } else {
          const admitData: IAdmitResident = {
            gate: gate,
            entryType: EntryType.Driver,
            carState: carState,
            carLicensePlate: carNumber,
            carExpirationDate: carExpirationDate,
            deviceId: deviceId,
            isEIQPassportUsed: true,
          };

          if (guest) {
            return guestService.admitGuest(residentId, addressId, guest.id, admitData).then((result) => {
              notification.success({ message: 'Guest has been admitted' });
              plateRecognitionContext.clear();
              playSound(successSound);
            });
          } else {
            return guestService.admitResident(residentId, addressId, admitData).then((result) => {
              notification.success({ message: 'The resident  has been admitted' });
              plateRecognitionContext.clear();
              playSound(successSound);
            });
          }
        }
      })
      .catch((error: any) => {
        playSound(errorSound);
        if (error.response?.status === 404) {
          notification.error({ message: error.response.data.message, duration: 0 });
        }
      });
  };

  const getScanner = (): Html5Qrcode => {
    if (scanner.current) {
      return scanner.current;
    }
    const result = new Html5Qrcode(QRcodeReaderId);
    scanner.current = result;

    return result;
  };

  useEffect(() => {
    if (isOpen) {
      const html5QrCode = getScanner();

      html5QrCode
        .start(
          { facingMode: 'environment' },
          {
            fps: 5,
            qrbox: { width: 250, height: 250 },
          },
          (decodedText, decodedResult) => {
            if (decodedText) {
              getDecodedTextHandle(getCode(decodedText));
            }
            handleOnClose();
          },
          (error) => {
            console.log(error);
          },
        )
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isOpen]);

  const onScanDetected = (code: string) => {
    const result = code.trim();
    if (!/^\d+$/.test(result)) {
      return;
    }
    getDecodedTextHandle(result);
  };

  return (
    <>
      <Modal centered width={400} open={isOpen} wrapClassName="custom-modal custom-modal--so" closable={false} title="EIQ Passport scanner">
        <div className="qr-code-scanner" id={QRcodeReaderId} />
        <Space className="footer">
          <Button className="eiq-button" type="link" onClick={handleOnClose}>
            Cancel
          </Button>
        </Space>
      </Modal>
      <QRCodeAdmitModal isOpen={admitModal.isOpenModal} onClose={admitModal.hideModal} initData={admitModal.initData} />
      <QRCodeScanDetector onScan={onScanDetected} />
    </>
  );
});

export default React.memo(QRCodeScannerModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
