import React, { forwardRef } from 'react';
import { IEiqPrint } from './eiq-print.types';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import TableBase from 'common/components/table/table-base';
import { formatAddressLabel } from 'common/helpers/address.helper';
import './eiq-print.scss';
import { Row } from 'antd';

const EiqPrint = forwardRef(({ firstName, lastName, address, data, columns, rowHeight }: IEiqPrint, ref: any) => {
  const tableControls = useTableControls({
    clientSide: true,
    data: data,
    initialColumns: columns,
  });

  return (
    <div className="print">
      <div className="printBody" ref={ref}>
        <div className="printContent">
          <div>
            <span className="name">Name:</span>{' '}
            <span>
              {firstName} {lastName}
            </span>
          </div>
          <div>
            <span className="name">Address:</span>
            <span>{` ${formatAddressLabel(address)}`}</span>
          </div>
        </div>
        <TableContextProvider controls={tableControls}>
          <Row className="table-wrapper" style={{ height: `${60 + (data?.length || 0) * (rowHeight || 32)}px` }}>
            <TableBase rowHeight={rowHeight} />
          </Row>
        </TableContextProvider>
      </div>
    </div>
  );
});

export default EiqPrint;
